import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=7bc66c35&scoped=true"
import script from "./ErrorPage.vue?vue&type=script&lang=js"
export * from "./ErrorPage.vue?vue&type=script&lang=js"
import style0 from "./ErrorPage.css?vue&type=style&index=0&id=7bc66c35&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bc66c35",
  null
  
)

export default component.exports