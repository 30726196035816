<template>
  <Page>
    <template v-slot:content>
      <Section>
        <EmptyResults
          :title="$t('pages.errorpage.title')"
          :subtitle="$t('pages.errorpage.subtitle')"
          :message="$t('pages.errorpage.message')"
        >
          <template v-slot:buttons>
            <div class="button--container">
              <Button
                type="tertiary"
                :label="$t('pages.errorpage.back')"
                @onClick="backHomePage"
              />
              <Button
                type="secondary"
                :label="$t('pages.errorpage.search')"
                @onClick="backSearchPage"
              />
            </div>
          </template>
        </EmptyResults>
      </Section>
    </template>
  </Page>
</template>

<script>
import { EmptyResults, Button } from "@sc/ds-ui-component-library";
import { Page } from "./../../templates";
import { Section } from "./../../atoms";
import { PAGES } from "@/enums/pages";

export default {
  name: "ErrorPage",
  components: {
    EmptyResults,
    Page,
    Section,
    Button
  },
  methods: {
    backHomePage() {
      console.debug("Back to HomePage...");
      this.$router.push({
        name: PAGES.HOME
      });
    },
    backSearchPage() {
      console.debug("Back to SearchPage...");
      this.$router.push({
        name: PAGES.SEARCH
      });
    }
  }
};
</script>

<style scoped lang="css" src="./ErrorPage.css"></style>
